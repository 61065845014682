import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "ConTainer",

  setup() {
    const router = useRouter();
    let isKeep = ref(false);
    let routeKey = ref(""); // let test = ref(23233);
    // onMounted(() => {
    //   setTimeout(() => {
    //     test.value = 98988;
    //   }, 1000);
    // });

    watch(() => router, (newVal, oldVal) => {
      console.log(newVal);
      console.log("是否重新加载页面", newVal.currentRoute._rawValue.meta.keepAlive); // console.log("是否有什么变化", newVal.currentRoute._rawValue.meta.group);

      isKeep.value = newVal.currentRoute._rawValue.meta.keepAlive;
      routeKey.value = newVal.currentRoute._rawValue.meta.group;
      console.log(routeKey.value);
    }, {
      deep: true,
      immediate: true
    }); // 开启深度监听

    return {
      isKeep,
      routeKey // test,

    };
  }

};