import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // banner列表
    async bannerList() {
        let data = {
            types: 'banner',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 导航列表
    async naviList() {
        let data = {
            types: 'navi_list',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 热销列表
    async starList() {
        let data = {
            types: 'star',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 首页推荐商品列表
    async recommendList() {
        let data = {
            types: 'recommend',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 热搜词列表
    async hotSearchList() {
        return await toGet(adminurl + "system/search/show/", store.getters.getShopId)
    },
    // 首页弹窗
    async windowList() {
        let data = {
            types: 'window',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 个人中心广告
    async advList() {
        let data = {
            types: 'adv',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 分类页样式
    async cateType() {
        let data = {
            types: 'cate_type',
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/show", data)
    },
    // 添加一个分类配置
    async insertCateType() {
        let data = {
            type: 'cate_type',
            value: 1,
            sort: 0,
            callback: '',
            img_url: "",
            shop_id: store.getters.getShopId
        };
        return await toPost(adminurl + "system/index/add", data)
    },

    // 状态设置
    async shopConfigStatus(data) {
        return await toPost(adminurl + "system/index/status", data)
    },
    // 删除首页配置
    async deleteShopConfig(id) {
        return await toGet(adminurl + "system/index/delete/", id)
    },
    // 添加首页配置
    async addShopConfig(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/index/add", data)
    },
    // 修改首页配置
    async updateShopConfig(data) {
        return await toPost(adminurl + "system/index/update", data)
    },
    // 修改首页配置
    async addShopSearch(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/search/add", data)
    },
    // 修改首页配置
    async updateShopSearch(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/search/update", data)
    },


    // 商城服务信息
    async vipServer() {
        return await toGet(adminurl + "system/show_vip_server/", store.getters.getShopId)
    },
    // 修改服务信息
    async updateVipServer(data) {
        return await toGet(adminurl + `system/update_vip_server/${store.getters.getShopId}/`, data)
    },

    // 展示协议
    async agreementConfig(id) {
        return await toGet(adminurl + "index/agreement_config/", id)
    },
    // 修改协议
    async updateAgreement(data) {
        return await toPost(adminurl + "index/update_agreement", data)
    },


    // 门店配置
    async showShopRatio() {
        return await toGet(adminurl + "system/show_shop_ratio/", store.getters.getShopId)
    },
    // 修改门店配置
    async modifyShopRatio(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/modify_shop_ratio", data)
    },


    // 门店登录
    async shopLogin(data) {
        return await toPost(weburl + "shop/login", data)
    },
    // 门店列表
    async shopList(data) {
        return await toPost(weburl + "shop/infos", data)
    },
    // 门店信息
    async shopInfo(id) {
        return await toGet(weburl + "shop/info/", id)
    },
    // 新增门店
    async addShopInfo(data) {
        return await toPost(weburl + "shop/add_info", data)
    },
    // 修改门店
    async modifyShopInfo(data) {
        data = {
            ...data,
            id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/modify_info", data)
    },

    // 门店支付相关配置
    async shopPayConfig() {
        return await toGet(adminurl + "pay_config/show/", store.getters.getShopId)
    },
    // 门店支付相关配置
    async modifyPayConfig(data) {
        return await toPost(adminurl + "pay_config/modify_config", data)
    },

    // 门店预约时间段配置
    async reservationList() {
        return await toGet(weburl + "shop/reservation/show/", store.getters.getShopId)
    },
    // 新增预约时间段配置
    async addReservation(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/reservation/add", data)
    },
    // 修改预约时间段配置
    async EditReservation(data) {
        return await toPost(weburl + "shop/reservation/modify", data)
    },
    // 删除预约时间段配置
    async DeleteReservation(id) {
        return await toGet(weburl + "shop/reservation/del/", id)
    },

    // 门店可预约日期情况
    async reservationDays(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "user/show_time_slot", data)
    },

    // 展示休息日配置
    async showRest() {
        return await toGet(weburl + "shop/scheduling/show/", store.getters.getShopId)
    },
    // 新增休息日
    async addRest(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/scheduling/add", data)
    },
    // 删除休息日
    async delRest(id) {
        return await toGet(weburl + "shop/scheduling/del/", id)
    },
}