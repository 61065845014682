import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 员工列表
    async staffList() {
        return await toGet(weburl + "shop/staff/show/", store.getters.getShopId)
    },
    // 新增员工
    async addStaff(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/staff/add/", data)
    },
    // 员工状态
    async updateStaff(data) {
        return await toPost(weburl + "shop/staff/modify", data)
    },
    // 删除员工账号
    async deleteStaff(id) {
        return await toGet(weburl + "shop/staff/del/", id)
    },
    // 重置密码
    async resetStaffPwd(id) {
        return await toGet(weburl + "user/reset_pwd/", id)
    },

    // 员工服务单
    async staffServerOrder(data) {
        return await toPost(weburl + `shop/staff/staff_server_orders`, data)
    },

}