import { createRouter, createWebHistory } from "vue-router";
import routes from "./router";
import store from "../store";
import { webinfo } from "@/utils/config.js";

// 载入路由
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 设置路由守护
router.beforeEach((to, from, next) => {
  console.log("触发守卫，目标页面：");
  // console.log(from);
  console.log(to.name, ":", to.path);
  // 动态title
  document.title = to.meta.title ? to.meta.title : webinfo.name;
  if (to.name === "register") {
    next();
  }
  // 判断是否登录
  if (to.name !== "login") {
    const JSON_token = sessionStorage.getItem("lhShopAdminUserToken");
    if (JSON_token && typeof JSON.parse(JSON_token) == "object") {
      let tokenInfo = JSON.parse(JSON_token);
      if (!tokenInfo.token) {
        console.log("登录信息已失效，重新登录");
        tokenInfo.token = null;
        let JSON_data = JSON.stringify(tokenInfo);
        sessionStorage.setItem("lhShopAdminUserToken", JSON_data);
        next({ path: "/login" });
        return;
      }
    } else {
      console.log("无账号登录信息，重新登录");
      next({ path: "/login" });
      return;
    }
  }
  // 直接跳转
  next();
});

export default router;
