import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;


// 导出
export default {
    /**
     * 
     * 专区
     */
    // 展示专区
    async TypesIntegral() {
        return await toGet(weburl + "integral/good/label/show/", store.getters.getShopId)
    },
    // 新增专区
    async addTypesIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/label/add", data)
    },
    // 修改专区
    async editTypesIntegral(data) {
        return await toPost(weburl + "integral/good/label/update", data)
    },
    // 修改专区状态
    async typesStatusIntegral(data) {
        return await toPost(weburl + "integral/good/label/update_label_status", data)
    },
    // 删除专区
    async deleteTypesIntegral(id) {
        return await toGet(weburl + "integral/good/label/delete/", id)
    },

    /**
     * 
     * 类别
     */
    // 展示类别
    async ClassIntegral() {
        return await toGet(weburl + "integral/good/category/show/", store.getters.getShopId)
    },
    // 新增类别
    async addClassIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/category/add", data)
    },
    // 修改类别
    async editClassIntegral(data) {
        return await toPost(weburl + "integral/good/category/update", data)
    },
    // 删除类别
    async deleteClassIntegral(id) {
        return await toGet(weburl + "integral/good/category/delete/", id)
    },

    /**
     * 
     * 商品规格
     */
    // 展示规格
    async SpecsIntegral() {
        return await toGet(weburl + "integral/good/spec/show/", store.getters.getShopId)
    },
    // 新增规格
    async addSpecsIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/spec/add", data)
    },
    // 修改规格
    async editSpecsIntegral(data) {
        return await toPost(weburl + "integral/good/spec/update", data)
    },
    // 删除规格
    async deleteSpecsIntegral(data) {
        return await toPost(weburl + "integral/good/spec/delete", data)
    },

    /**
     * 
     * 商品
     */
    async goodsListIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/show", data)
    },
    // 上下架商品
    async goodsStatusIntegral(data) {
        return await toPost(weburl + "integral/good/modify_good_status", data)
    },
    // 删除商品
    async deleteGoodsIntegral(id) {
        return await toGet(weburl + "integral/good/delete/", id)
    },
    // 商品规格列表
    async goodsBaseIntegral(id) {
        return await toGet(weburl + "integral/good/good_base/", id)
    },
    // 上下架规格
    async goodsBaseStatusIntegral(data) {
        return await toPost(weburl + "integral/good/modify_good_base_status", data)
    },
    // 修改商品信息
    async updateGoodsIntegral(data) {
        return await toPost(weburl + "integral/good/update", data)
    },
    // 修改商品规格信息
    async updateGoodsBaseIntegral(data) {
        return await toPost(weburl + "integral/good/update_base", data)
    },
    // 新增商品规格
    async addGoodsBaseIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/add_base", data)
    },
    // 商品评论
    async goodsCommentsIntegral(id) {
        return await toGet(weburl + "integral/shopgood/product_comments/", id)
    },
    // 新增商品
    async addGoodsIntegral(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/good/add", data)
    },
    // 删除子商品
    async delBaseIntegral(id) {
        return await toGet(weburl + "integral/good/del_base/", id)
    },


    // 获取运费模板
    async transport() {
        return await toGet(adminurl + "order/transport/show/", store.getters.getShopId)
    },
    // 添加运费模板
    async addTransport(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "order/transport/add", data)
    },
    // 修改运费模板
    async updateTransport(data) {
        return await toPost(adminurl + "order/transport/update", data)
    },
    // 运费模板地区
    async transportArea(data) {
        return await toPost(adminurl + "order/transport/show_city_info/", data)
    },
    // 运费模板地区
    async updateTransportArea(data) {
        return await toPost(adminurl + "order/transport/config_area", data)
    },
    // 删除运费模板
    async deleteTransport(id) {
        return await toGet(adminurl + "order/transport/delete/", id)
    },
}