import { createApp } from "vue";
// 导入vue实例
import App from "./App.vue";
// 导入VUEX
import store from "./store";
// 导入路由
import router from "./router";
// 导入element plus框架
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import * as ElIcons from "@element-plus/icons-vue";
// 导入axios
import axios from "axios";
import VueAxios from "vue-axios";
import "@/axios";
// 导入公共样式
import "@/assets/public.scss";
import "@/assets/media.scss";
// 导入配置变量
import { imgapi, adminurl, weburl, uploadApi, webinfo } from "@/utils/config.js";
// 导入drag方法
import { drag } from '@/utils/drag.js'
// 导入公共方法
import tools from "@/utils/public.js";
// 导入后台API
import apis from "@/utils/api.js";

// 创建应用
const app = createApp(App);

// 注册drag拖拽指令
app.directive('drag', drag);

// 全局变量加入公共函数
app.config.globalProperties = {
  ...app.config.globalProperties,
  ...tools
};
console.log('公共方法--：', app.config.globalProperties)

// 判断设备尺寸
app.config.globalProperties.$isM = document.body.clientWidth < 768;

// 删除OSS图片
// app.config.globalProperties.delOssImg = delOssImg;

// 后端统一接口
app.config.globalProperties.api = apis
// oss图片域名
app.config.globalProperties.$imgapi = imgapi;
// 接口域名地址
app.config.globalProperties.$adminurl = adminurl;
app.config.globalProperties.$weburl = weburl;
app.config.globalProperties.$uploadApi = uploadApi;
// 站点信息
app.config.globalProperties.$webInfo = webinfo;

// 图标
for (let iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName]);
}
app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueAxios, axios)
  .use(ElementPlus, { locale: zhCn })
  .mount("#app");
