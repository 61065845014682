import { createApp } from "vue";
const app = createApp({});
import axios from "axios";
import qs from "qs"; // 引入qs，axios在post参数时，要通过qs来格式化数据

// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.data = qs.stringify(config.data); // 对参数进行序列化
    }
    return config;
  },
  (error) => {
    console.log("请求出错啦");
    console.dir(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
app.config.globalProperties.$axios = axios;
