import Home from "@/views/index.vue";
import login from "@/views/login.vue";
import notpath from "@/views/lose.vue";
// 
import store from "../store";
console.log('打印store数据：----', store.state);
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: "/index",
    children: [
      // Echarts图表示例 和 导出表格
      {
        path: "/index",
        name: "index",
        meta: {
          group: "",
          // monitor
          // groupName: "首页",
          name: "首页",
          icon: "monitor",
          info: "monitor/首页",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/home/home.vue"),
      },


      // 商城段
      // 会员端配置
      {
        path: "/sysconfigVip",
        name: "sysconfigVip",
        meta: {
          group: "Operation",
          groupName: "配置管理",
          name: "商城配置",
          icon: "Open",
          info: "Open/配置管理/商城配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/configVip.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        meta: {
          group: "Operation",
          groupName: "配置管理",
          name: "排单日历",
          icon: "Trophy",
          info: "Open/配置管理/排单日历",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/manage/calendar.vue"),
      },
      // {
      //   path: "/kdiconfig",
      //   name: "kdiconfig",
      //   meta: {
      //     group: "Open",
      //     groupName: "配置管理",
      //     name: "通证发放配置",
      //     icon: "Coin",
      //     info: "Open/配置管理/会员端通证发放配置",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/kdiconfig.vue"),
      // },
      // {
      //   path: "/identity",
      //   name: "identity",
      //   meta: {
      //     group: "Open",
      //     groupName: "配置管理",
      //     name: "身份配置",
      //     icon: "Suitcase",
      //     info: "Open/配置管理/会员端身份奖励及晋升配置",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/manage/identity.vue"),
      // },
      // 员工管理
      {
        path: "/staff",
        name: "staff",
        meta: {
          group: "Avatar",
          groupName: "员工管理",
          name: "员工列表",
          icon: "User",
          info: "Avatar/员工管理/员工列表",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/staff/staff.vue"),
      },
      // 业绩管理
      {
        path: "/achievement",
        name: "achievement",
        meta: {
          group: "Avatar",
          groupName: "员工管理",
          name: "员工业绩",
          icon: "DataAnalysis",
          info: "Avatar/员工管理/员工业绩",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/staff/achievement.vue"),
      },
      // 优惠券
      // {
      //   path: "/actconf",
      //   name: "actconf",
      //   meta: {
      //     group: "Avatar",
      //     groupName: "员工管理",
      //     name: "用户优惠券",
      //     icon: "Ticket",
      //     info: "Avatar/员工管理/用户优惠券",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/user/actconf.vue"),
      // },
      // 实名
      // {
      //   path: "/audit",
      //   name: "audit",
      //   meta: {
      //     group: "User",
      //     groupName: "用户管理",
      //     name: "实名认证",
      //     icon: "Checked",
      //     info: "User/用户管理/实名认证",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/user/identifyAudit.vue"),
      // },
      // // 资产管理
      // {
      //   path: "/apply",
      //   name: "apply",
      //   meta: {
      //     group: "User",
      //     groupName: "资产管理",
      //     name: "提现审核",
      //     icon: "Management",
      //     info: "User/资产管理/提现审核",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/wallet/apply.vue"),
      // },

      // 订单管理
      {
        path: "/order",
        name: "order",
        meta: {
          group: "Management",
          groupName: "订单管理",
          name: "订单列表",
          icon: "List",
          info: "Management/订单管理/订单列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/order.vue"),
      },
      {
        path: "/orderexchange",
        name: "orderexchange",
        meta: {
          group: "Management",
          groupName: "订单管理",
          name: "服务预约单",
          icon: "Collection",
          info: "Management/订单管理/服务预约单",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/orderexchange.vue"),
      },
      {
        path: "/orderRequest",
        name: "orderRequest",
        meta: {
          group: "Management",
          groupName: "订单管理",
          name: "售后单",
          icon: "Tickets",
          info: "Management/订单管理/售后单",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/orderRequest.vue"),
      },
      {
        path: "/orderIntegral",
        name: "orderIntegral",
        meta: {
          group: "Management",
          groupName: "订单管理",
          name: "积分订单",
          icon: "Notebook",
          info: "Management/订单管理/积分订单",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/orderIntegral.vue"),
      },
      // {
      //   path: "/ordergoods",
      //   name: "ordergoods",
      //   meta: {
      //     group: "Management",
      //     groupName: "商城订单",
      //     name: "导出发货单",
      //     icon: "Collection",
      //     info: "Management/商城订单/导出发货单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/ordergoods.vue"),
      // },
      // {
      //   path: "/afterorder",
      //   name: "afterorder",
      //   meta: {
      //     group: "Management",
      //     groupName: "订单管理",
      //     name: "售后订单",
      //     icon: "DocumentDelete",
      //     info: "Management/订单管理/售后订单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/afterorder.vue"),
      // },


      // 商品管理
      {
        path: "/goods",
        name: "goods",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "商品列表",
          icon: "Goods",
          info: "Box/商品管理/商品列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/goods/list.vue"),
      },
      {
        path: "/addgoods",
        name: "addgoods",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "新增商品",
          icon: "CirclePlus",
          info: "Box/商品管理/新增商品",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/addgoods.vue"),
      },
      {
        path: "/typesVip",
        name: "typesVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "专区管理",
          icon: "TurnOff",
          info: "Box/商品管理/专区管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/types.vue"),
      },
      {
        path: "/classifyVip",
        name: "classifyVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "类别管理",
          icon: "Notification",
          info: "Box/商品管理/类别管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/classify.vue"),
      },
      {
        path: "/specVip",
        name: "specVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "规格管理",
          icon: "Menu",
          info: "Box/商品管理/规格管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/spec.vue"),
      },


      // 积分商品管理
      {
        path: "/integral_goods",
        name: "integral_goods",
        meta: {
          group: "ShoppingBag",
          groupName: "积分商品",
          name: "积分商品列表",
          icon: "Goods",
          info: "ShoppingBag/积分商品/积分商品列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/integralGoods/list.vue"),
      },
      {
        path: "/integral_addgoods",
        name: "integral_addgoods",
        meta: {
          group: "ShoppingBag",
          groupName: "积分商品",
          name: "新增积分商品",
          icon: "CirclePlus",
          info: "ShoppingBag/积分商品/新增积分商品",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/integralGoods/addgoods.vue"),
      },
      {
        path: "/integral_classify",
        name: "integral_classify",
        meta: {
          group: "ShoppingBag",
          groupName: "积分商品",
          name: "类别管理",
          icon: "Notification",
          info: "ShoppingBag/积分商品/类别管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/integralGoods/classify.vue"),
      },
      {
        path: "/integral_spec",
        name: "integral_spec",
        meta: {
          group: "ShoppingBag",
          groupName: "积分商品",
          name: "规格管理",
          icon: "Menu",
          info: "ShoppingBag/积分商品/规格管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/integralGoods/spec.vue"),
      },
      {
        path: "/logistics",
        name: "logistics",
        meta: {
          group: "ShoppingBag",
          groupName: "积分商品",
          name: "物流模板",
          icon: "Van",
          info: "ShoppingBag/积分商品/物流模板",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/integralGoods/logistics.vue"),
      },



      // 活动相关
      {
        path: "/teamgoods",
        name: "teamgoods",
        meta: {
          group: "Star",
          groupName: "活动管理",
          name: "团购商品",
          icon: "Present",
          info: "Star/活动管理/团购商品",
          keepAlive: true,
          role: "admin"
        },
        component: () => import("@/views/page/active/teamgoods.vue"),
      },
      {
        path: "/timegoods",
        name: "timegoods",
        meta: {
          group: "Star",
          groupName: "活动管理",
          name: "秒杀活动",
          icon: "Timer",
          info: "Star/活动管理/秒杀活动",
          keepAlive: true,
          role: "admin"
        },
        component: () => import("@/views/page/active/integral.vue"),
      },





      // 商城端，会员管理
      {
        path: "/user",
        name: "user",
        meta: {
          group: "User",
          groupName: "用户管理",
          name: "门店用户",
          icon: "UserFilled",
          info: "User/用户管理/门店用户",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/user.vue"),
      },
      // 优惠券
      {
        path: "/actconf",
        name: "actconf",
        meta: {
          group: "User",
          groupName: "用户管理",
          name: "用户优惠券",
          icon: "Ticket",
          info: "User/用户管理/用户优惠券",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/actconf.vue"),
      },

      // },
      // 资产管理
      // {
      //   path: "/apply",
      //   name: "apply",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "提现审核",
      //     icon: "Management",
      //     info: "Wallet/资产管理/提现审核",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/apply.vue"),
      // },
      // {
      //   path: "/transfer",
      //   name: "transfer",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "转账记录",
      //     icon: "Switch",
      //     info: "Wallet/资产管理/转账记录",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/transfer.vue"),
      // },
      // {
      //   path: "/ruleconfig",
      //   name: "ruleconfig",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "规则配置",
      //     icon: "Operation",
      //     info: "Wallet/资产管理/规则配置",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/ruleconfig.vue"),
      // },
      // {
      //   path: "/output",
      //   name: "output",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "产出配置",
      //     icon: "Operation",
      //     info: "Wallet/资产管理/产出配置",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/output.vue"),
      // },
      // {
      //   path: "/payconfig",
      //   name: "payconfig",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "支付配置",
      //     icon: "Open",
      //     info: "Wallet/资产管理/支付配置",
      //     keepAlive: true,
      //   },
      //   component: () => impor@/views/page/wallet/ruleconfig.vuevue"),
      // },
      // {
      //   path: "/outputinfo",
      //   name: "outputinfo",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "分红记录",
      //     icon: "Odometer",
      //     info: "Wallet/资产管理/分红记录",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/outputInfo.vue"),
      // },
      // {
      //   path: "/actconf",
      //   name: "actconf",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "优惠券管理",
      //     icon: "CollectionTag",
      //     info: "Wallet/资产管理/优惠券管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/actconf.vue"),
      // },
      // {
      //   path: "/ruleintro",
      //   name: "ruleintro",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "商城设置",
      //     name: "规则说明",
      //     icon: "Document",
      //     info: "Wallet/资产管理/规则说明",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/ruleintro.vue"),
      // },
      // {
      //   path: "/earningslog",
      //   name: "earningslog",
      //   meta: {
      //     group: "Wallet",
      //     groupName: "资产管理",
      //     name: "订单释放",
      //     icon: "Coin",
      //     info: "Wallet/资产管理/订单释放",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/wallet/earningsLog.vue"),
      // },

      // 文章管理
      // {
      //   path: "/article",
      //   name: "article",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "资讯列表",
      //     icon: "Document",
      //     info: "Tickets/文章管理/资讯列表",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/article.vue"),
      // },
      // {
      //   path: "/articleclass",
      //   name: "articleclass",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "文章分类",
      //     icon: "ScaleToOriginal",
      //     info: "Tickets/文章管理/文章分类",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/articleClass.vue"),
      // },
      // {
      //   path: "/notify",
      //   name: "notify",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "通知管理",
      //     icon: "ChatLineSquare",
      //     info: "Tickets/文章管理/通知管理",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/notify.vue"),
      // },
      // {
      //   path: "/advertise",
      //   name: "advertise",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "文章管理",
      //     name: "广告管理",
      //     icon: "Position",
      //     info: "Notebook/文章管理/广告管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/article/advertise.vue"),
      // },


      // 商城设置
      {
        path: "/shop",
        name: "shop",
        meta: {
          group: "",
          // Iphone
          groupName: "店铺设置",
          name: "门店装修",
          icon: "Iphone",
          info: "Iphone/门店装修",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/shop.vue"),
      },
      // {
      //   path: "/service",
      //   name: "service",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "店铺设置",
      //     name: "服务信息",
      //     icon: "Headset",
      //     info: "Iphone/店铺设置/服务信息",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/service.vue"),
      // },
      // {  
      //   path: "/agreementVip",
      //   name: "agreementVip",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "店铺设置",
      //     name: "用户协议",
      //     icon: "DocumentCopy",
      //     info: "Iphone/店铺设置/用户协议",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/agreementVip.vue"),
      // },
      // {
      //   path: "/privacyVip",
      //   name: "privacyVip",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "店铺设置",
      //     name: "隐私协议",
      //     icon: "Document",
      //     info: "Iphone/店铺设置/隐私协议",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/privacyVip.vue"),
      // },
      // {
      //   path: "/agency",
      //   name: "agency",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "代理商协议",
      //     icon: "Notebook",
      //     info: "Iphone/商城设置/代理商协议",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/shop/agency.vue"),
      // },
      // {
      //   path: "/intro",
      //   name: "intro",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "店铺设置",
      //     name: "门店介绍",
      //     icon: "Tickets",
      //     info: "Iphone/店铺设置/门店介绍",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/shop/intro.vue"),
      // },
      // {
      //   path: "/version",
      //   name: "version",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "APP版本",
      //     icon: "Cellphone",
      //     info: "Iphone/商城设置/APP版本管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/shop/version.vue"),
      // },
      // 设置
      {
        path: "/setting",
        name: "setting",
        meta: {
          group: "",
          name: "设置",
          icon: "Setting",
          info: "setting/设置中心",
          keepAlive: false,
          role: "admin/caiwu"
        },
        component: () => import("@/views/page/setting/setting.vue"),
      },
    ],
  },
  {
    path: "/:pathMacth(.*)",
    component: notpath,
  },
];

export default routes;
