import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const data = reactive({
      loseImg: require("@/assets/404.jpg")
    });

    const toindex = () => {
      router.push({
        name: "index"
      });
    };

    return { ...toRefs(data),
      toindex
    };
  }

};